const config = {
    api: {
        base: `https://api-development.boxnow.gr/api/v1`,
    },
    sentry: {
        dsn: 'https://6ccced3b404c4549ad7c05d588eb44f9@o1006836.ingest.sentry.io/5980659',
    },
    // This is correct, the domain for dev isn't available
    trackBoxNowUrl: 'https://example.com',
    devTools: true,
};

export type ConfigDevelopment = typeof config;

export default config;
